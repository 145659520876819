import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// 引入全局子组件
import FootNav from './components/footnav/FootNav'
import Vant from 'vant';
import 'vant/lib/index.css';
import api from './http/api';
import publicJs from './publicJs/com';
import utils from './utils';
// import './config/rem'  //px转rem
import { Notify } from 'vant';
import { IndexBar, IndexAnchor } from "vant";
import { Checkbox, CheckboxGroup } from 'vant';
import { Overlay } from 'vant';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import { post, get, patch, put } from './http'
import loading from './components/loading.vue'
import { Tab, Tabs } from 'vant';
import VueClipboard from 'vue-clipboard2'

import waterfall from 'vue-waterfall2'
Vue.use(waterfall)
// import VideoPlayer from 'vue-video-player'
// require('vue-video-player/src/custom-theme.css')
// require('video.js/dist/video-js.css')
//引入
import moment from 'moment';
var VueTouch = require('vue-touch')
Vue.use(Tab).use(Tabs);
Vue.use(Overlay);
// Vue.use(VideoPlayer)
// 懒加载
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad, {
  preLoad: 1,
  // error: require('./assets/images/logo_loading.jpg'),
  // loading: require('./assets/images/imgLoading.gif'),
  attempt: 2,
})
import VueAMap from 'vue-amap'
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '2f0a4baa6518a3d23a18177d72c41df5',
  plugin: [//高德地图扩展插件(按需添加)
    'AMap.Autocomplete', // 输入提示插件
    'AMap.PlaceSearch', // POI搜索插件
    'AMap.Scale', // 右下角缩略图插件 比例尺
    'AMap.OverView', // 地图鹰眼插件
    'AMap.ToolBar', // 地图工具条
    'AMap.MapType', // 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    'AMap.PolyEditor', // 编辑 折线多，边形
    'AMap.CircleEditor', // 圆形编辑器插件
    'AMap.Geolocation' // 定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  uiVersion: '1.1', // ui库版本，不配置不加载,
  v: '2.0'
})
Vue.use(Notify);
Vue.use(VueTouch, { name: 'v-touch' })
// 把api挂载成全局变量
Vue.prototype.$Toast = Toast
Vue.prototype.$api = api
Vue.prototype.$Dialog = Dialog
Vue.prototype.$Toast = Toast
Vue.prototype.$publicJs = publicJs
Vue.prototype.$utils = utils
// 定义全局变量
Vue.prototype.$post = post
Vue.prototype.$get = get
Vue.prototype.$patch = patch
Vue.prototype.$put = put
//挂载到原型
Vue.prototype.$moment = moment;
//汉化，否则显示日期格式是国外的日期格式
moment.locale("zh-CN");
// 注册全局组件
Vue.component('FootNav', FootNav)
Vue.component('loading', loading)
// \注册地址
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Vant);
Vue.use(Dialog);
Vue.config.productionTip = false
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(VueClipboard)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
