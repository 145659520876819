
<template>
  <div class="searchBar navigate__bar">
    <ul>
      <van-tabs @click="scrollTo" v-model="navIndex">
        <van-tab v-for="(item, index) in titles" :title="item.name" :key="index" />
      </van-tabs>
    </ul>
    <ul class="isFixed" v-if="searchBarFixed == true" :style="{ 'top': tops + 'px' }">
      <van-tabs @click="scrollTo" v-model="navIndex">
        <van-tab v-for="(item, index) in titles" :title="item.name" :key="index" />
      </van-tabs>
    </ul>
  </div>
</template>

 
  
<script>
export default {
  name: "",
  props: {
    titles: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      tops: 40,
      navIndex: 0,

      searchBarFixed: false,
    };
  },
  components: {},
  methods: {
    /**
      * 页面滚动事件监听
    
    */
    onScroll() {
      // 获取所有锚点元素
      // const navContents = document.getElementById('isFixed')
      //    console.log(navContents.length)

      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 组件吸附顶部
      var offsetTop = document.querySelector('.navigate__bar')?.offsetTop;

      if (scrollTop > (offsetTop - 40)) {
        this.searchBarFixed = true;
        // 所有锚点元素的 offsetTop
        var top = offsetTop - scrollTop

        if (top > 0) {
          this.tops = top;
        } else {
          this.tops = 0;
        }
      } else {
        this.searchBarFixed = false;
        //    navContents.style.marginTop="0rem"

      }
    },

    /**
      * 跳转到指定索引的元素
      * @param index
      */
    scrollTo(index, title) {
      this.$emit('getIndex', index)
    }


  },
  mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll, false)
  },
  computed: {},
  watch: {},
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  },
};
</script>
  
 
<style scoped lang="scss">
.searchBar {
  width: 100%;

  .isFixed {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 999;
  }

  ul {
    height: 2.7rem;
    line-height: 2.7rem;
    display: flex;
    margin: 0;
    padding: 0;

    li {
      font-size: 0.8rem;
      flex: 1;
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
      align-content: center;
      position: relative;
      background-color: #fff;

      &.active {
        color: #847ec3;
        background-color: #e2e2e2;

        &:after {
          content: " ";
          position: absolute;
          height: 1px;
          width: 30px;
          bottom: 6px;
          left: calc(50% - 15px);
          border-top: 2px #847ec3 solid;
        }
      }
    }
  }
}
</style>
	
