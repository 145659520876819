<template>
  <!-- 推荐 -->
  <div class="container1">
    <div class="topTitle">
      <div
        style="background: rgba(210,59,46,0.10); height: 1.1rem; width: 1.1rem;border-radius: 8px;margin-left: 3.7rem;">
      </div>
      <div style="margin-top: -1rem;">精品分类 </div>
    </div>
    <div ref="wrapper" class="wrapper">
      <div class="flex" style="flex: 1;padding-bottom: 1rem;">
        <div v-for="(item, index) in recommended" :key="index" class="recomen" @click="goodOne(index, item.name)">
          <div class="ellipsis">{{ item.name }}</div>
          <div class="ellipsisImg">
            <img alt="" class="img" v-lazy="item.icon" @load="loadfinish" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BScroll from "better-scroll";
import { Form, Toast } from "vant";
import { Dialog } from "vant";
export default {
  name: "",
  props: {
    recommended: {
      type: Array,
    },
  },
  data() {
    return {
      username: "",
    };
  },
  components: {},
  methods: {
    // 横向滚动
    scroll() {
      this.$nextTick(() => {

        this.scroll = new BScroll(this.$refs.wrapper, {
          startX: 0, // 配置的详细信息请参考better-scroll的官方文档，这里不再赘述
          click: true,
          scrollX: true,
          scrollY: false,
          mouseWheel: true,//开启鼠标滚轮
          disableMouse: false,//启用鼠标拖动
          disableTouch: false,//启用手指触摸
          // 在手机端 让手指放在滚动区域也可以竖向滚动
          eventPassthrough: "vertical",
        });

      });
    },
    //  查看详情
    goodOne(val,indexName) {
      // console.log(val);
      // //  传递参数
      this.$router.push({
        path: "/Classification",
        query: {
          activeKey: val,
          indexName: indexName
        },
      });
    },
    // 添加购物车
    addCar(val) {
      // console.log(val);
      if (this.$store.state.nickname) {
        this.$api
          .addShop({
            id: val,
          })
          .then((res) => {
            Toast.success("加入购物车成功");
            // 请求购物车数据
            // 改变角标
            this.getData();

            // console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Dialog.confirm({
          title: "未登录",
          message: "是否去登陆",
        })
          .then(() => {
            this.$router.push("/Login");
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    // 滚动
    // 购物车数据
    getData() {
      this.$api
        .getCard()
        .then((res) => {
          // console.log(res);
          if (res.code === 200) {
            this.$store.commit("setCarNum", res.shopList.length);
            localStorage.setItem(`${this.username}carNum`, res.shopList.length);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    loadfinish() {
      this.scroll && this.scroll.refresh()
    }
  },
  mounted() {
    localStorage.getItem("userInfo")
      ? (this.username = JSON.parse(localStorage.getItem("userInfo")).username)
      : "";
    this.$nextTick(() => {
      this.scroll();
    });
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.container1 {
  margin-top: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;

  .wrapper {
    display: flex;
    overflow: hidden;
  }

  .recomen {
    width: 6rem;
    height: 7rem;
    margin-right: 0.5rem;
    background-color: white;
    border-radius: 0.3rem;

    .ellipsisImg {
      margin-top: 0.375rem;
      width: 4.375rem;
      height: 4.625rem;
      margin-left: 1.125rem;
      margin-bottom: 0.25rem;
      font-size: 0;
      text-align: center;
    }

    .img {
      height: 4.375rem;
      width: 4.375rem;
      border-radius: 0.3rem;
      vertical-align: middle;
    }

    .ellipsis {
      width: 3.5rem;
      height: 1.25rem;
      font-size: 0.875rem;
      letter-spacing: 0;
      font-weight: 500;
      margin-top: 0.75rem;
      margin-left: 0.75rem;
    }

    .del-line {
      text-decoration: line-through;
      padding-left: 0.2px;
      font-size: 0.5rem;
      color: rgb(186, 186, 186);
    }
  }

  .box-car {
    .icon {
      height: 100%;
      padding: 0.2rem 0.2rem;
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem;
      height: 1rem;
      background-color: rgb(254, 202, 58);

      .icon-xiazai1 {
        font-size: 22px !important;
      }
    }
  }
}

.m-tb-10 {
  margin-top: 0.5rem;
  font-size: 0.6rem;
}

.topTitle {
  font-size: 1.125rem;
  letter-spacing: 0;
  font-weight: 500;
  margin-bottom: 0.75rem;
}
</style>