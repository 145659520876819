// 封装所有的请求
import http from './index';
import { BDRequest } from '../http';

// 在index 文件下已将axios变为http
export default {
  // get参数不用{}包裹
  // 首页数据
  index() {
    return http.get('/recommend');
  },
  // 全品首页banner
  getBanner(code, type) {
    return http({
      url: `/product/order/queryRotationChart?showPositionCode=${code}&type=${type}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  // 主题
  getQueryRotation(data) {
    return http({
      url: `/product/order/queryRotation`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  // 主题点击确认
  queryTopicBoolean(data) {
    return http({
      url: `/product/info/queryTopicBoolean`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 搜索
  search({ value }) {
    return http.post('/search', {
      value,
    });
  },
  // 分类一级目录查询
  classify(ticketType) {
    return http({
      url: `/product/category/selectOneCategory?ticketType=${ticketType}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 分类二级目录查询
  secondClassify(id, cityCode) {
    return http({
      url: `/product/category/selectTwoCategory?pid=${id}&cityCode=${cityCode}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  classifyNew(bathId, upgradeFlag, cityCode) {
    return http({
      url: `/product/category/selectOneCategoryNew?bathId=${bathId}&upgradeFlag=${upgradeFlag}&cityCode=${cityCode}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },


  // 分类二级目录查询
  secondClassifyNew(id, bathId, upgradeFlag, cityCode) {
    return http({
      url: `/product/category/selectTwoCategoryNew?pid=${id}&bathId=${bathId}&upgradeFlag=${upgradeFlag}&cityCode=${cityCode}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  classifyNewImage(bathId, upgradeFlag, id, type, cityCode) {
    return http({
      url: `/product/category/selectOneCategoryByImage?bathId=${bathId}&upgradeFlag=${upgradeFlag}&id=${id}&type=${type}&cityCode=${cityCode}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 分类二级目录查询
  secondClassifyNewImage(pid, bathId, upgradeFlag, id, type, cityCode) {
    return http({
      url: `/product/category/selectTwoCategoryByImage?pid=${pid}&bathId=${bathId}&upgradeFlag=${upgradeFlag}&id=${id}&type=${type}&cityCode=${cityCode}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 分类二级目录查询
  secondCakeClassifyNew(id, bathId, upgradeFlag) {
    return http({
      url: `/product/category/selectCakeTwoCategoryNew?pid=${id}&bathId=${bathId}&upgradeFlag=${upgradeFlag}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  // 分类二级目录查询
  queryLabel(data) {
    return http({
      url: `/hostShow/queryLabel`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  sendSms(mobile) {
    return http({
      url: `/user/sendSms?mobile=${mobile}`,
      method: 'get',
    });
  },
  //登录手机号是否绑定微信
  confirmWxByMobile(mobile) {
    return http({
      url: `/wechat/confirmWxByMobile?mobile=${mobile}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //查询未支付订单
  queryUnpaidOrderInfo(userId) {
    return http({
      url: `/order/movie/queryUnpaidOrderInfo?userId=${userId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  sendPaySms(mobile) {
    return http({
      url: `/user/sendPaySms?mobile=${mobile}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //上传code
  codeCallback(code) {
    return http({
      url: `/wechat/callback?code=${code}`,
      method: 'get',
    });
  },
  // 绑定手机号
  bindUserByOpenId(data) {
    return http({
      url: `/wechat/bindUserByOpenId`,
      method: 'post',
      data: data,
    });
  },
  // 微信绑定手机号列表
  queryUserMobiles(openId) {
    return http({
      url: `/wechat/queryUserMobiles?openId=${openId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  loginSms(username, smsCode) {
    return http({
      url: `/oauth/token?grant_type=sms_code&platform=H5&scope=server&username=${username}&smsCode=${smsCode}`,
      method: 'post',
      headers: {
        Authorization:
          'Basic ' +
          'YTkyMjhkYmFiNjc0ODc5YTRkNmNiMTBmNjc5OWM1Y2I6ZGEzOWEzZWU1ZTZiNGIwZDMyNTViZmVmOTU2MDE4OTBhZmQ4MDcwOQ==',
      },
    });
  },
  loginWechat(username, mobile) {
    return http({
      url: `/oauth/token?grant_type=wechat_code&platform=H5&scope=server&username=${username}&mobile=${mobile}`,
      method: 'post',
      headers: {
        Authorization:
          'Basic ' +
          'YTkyMjhkYmFiNjc0ODc5YTRkNmNiMTBmNjc5OWM1Y2I6ZGEzOWEzZWU1ZTZiNGIwZDMyNTViZmVmOTU2MDE4OTBhZmQ4MDcwOQ==',
      },
    });
  },
  // 查询所有商品
  queryProductAll(name) {
    return http({
      url: `/product/info/queryProductAll?id=${name}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 查询收货地址
  getAddress(userId) {
    return http({
      url: `/address/queryAddress?userId=${userId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 添加收货地址
  addAddress(data) {
    return http({
      url: `/address/addAddress`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  // 修改收货地址
  updateAddress(data) {
    return http({
      url: `/address/updateAddress`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //查询城市热映影片
  queryHotMoviesByCity(cardId, cityId, filmName) {
    return http({
      url: `/movieMall/queryHotMoviesByCity?cardId=${cardId}&cityId=${cityId}&filmName=${filmName}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //是否显示缤纷商城
  queryShowColorfulMall(upgradeFlag, id) {
    return http({
      url: `/product/order/queryShowColorfulMall?upgradeFlag=${upgradeFlag}&id=${id}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //待放映影片
  queryWaitShowMovie(data) {
    return http({
      url: `/movieMall/queryWaitShowMovie`,
      method: 'get',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //查询城市影院列表
  queryCinemas(data) {
    return http({
      url: `/movieMall/queryCinemas`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //根据影片查影院和场次
  queryFutureFilmsByFilmId(data) {
    return http({
      url: `/movieMall/queryFutureFilmsByFilmId`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  //根据影片查影院和场次
  queryShowsByMovieAndFilm(data) {
    return http({
      url: `/movieMall/queryShowsByMovieAndFilm`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //根据影院查影片
  queryAllFilms(data) {
    return http({
      url: `/movieMall/queryAllFilms`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //查询座位
  queryShowSeats(data) {
    return http({
      url: `/movieMall/queryShowSeats`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  //锁座
  lockOrder(data) {
    return http({
      url: `/movieMall/lockOrder`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //查询可支付的福利卡
  queryPayBenefitCard(data) {
    return http({
      url: `/order/movie/queryPayBenefitCard`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // queryPayBenefitCard(cardId, cityCode, filmId, entrCode, maxSeatPrice) {
  //   return http({
  //     url: `/order/movie/queryPayBenefitCard?cardId=${cardId}&cityCode=${cityCode}&filmId=${filmId}&entrCode=${entrCode}&maxSeatPrice=${maxSeatPrice}`,
  //     method: 'get',
  //     headers: { 'Authorization': 'Bearer ' + this.getToken() }
  //   });
  // },
  //查询自营商品可支付的福利卡
  orderPayBenefitCard(cardId, entrCode, productIds) {
    return http({
      url: `/product/order/queryPayBenefitCard?cardId=${cardId}&entrCode=${entrCode}&productIds=${productIds}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  //查询自营商品可支付的福利卡（修改）
  queryPayBenefitCardNew(productIds, cardId) {
    return http({
      url: `/product/order/queryPayBenefitCardNew?cardId=${cardId}&productIds=${productIds}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //三方商品可支付的福利卡
  cakePayBenefitCard(cardId, entrCode) {
    return http({
      url: `/order/cake/queryPayBenefitCard?cardId=${cardId}&entrCode=${entrCode}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //支付
  payOrderInfo(data) {
    return http({
      url: `/order/movie/payOrderInfo`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //自营支付
  payProductInfo(data) {
    return http({
      url: `/product/order/payOrderInfo`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //三方支付
  payCakerInfo(data) {
    return http({
      url: `/order/cake/payOrderInfo`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //获取微信支付参数
  generatePayOrderInfo(data) {
    return http({
      url: `/api/cashier/generatePayOrderInfo`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //电影订单
  queryMovieOrderInfo(data) {
    return http({
      url: `order/movie/queryMovieOrderInfo`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //非电影订单
  queryCakeOrderInfo(data) {
    return http({
      url: `order/cake/queryCakeOrderInfo`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //全部订单
  queryOrderInfo(data) {
    return http({
      url: `/product/order/queryOrderInfo`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //取消订单
  cancelPayment(tripartiteOrderId) {
    return http({
      url: `/order/movie/cancelPayment?tripartiteOrderId=${tripartiteOrderId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //三方取消订单
  cakecancelPayment(tripartiteOrderId) {
    return http({
      url: `/order/cake/cancelPayment?tripartiteOrderId=${tripartiteOrderId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //自营取消订单
  productcancelPayment(tripartiteOrderId) {
    return http({
      url: `/product/order/cancelPayment?tripartiteOrderId=${tripartiteOrderId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 加入购物车
  addShop(data) {
    return http({
      url: `/shoppingCart/add`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  prePurchase(data) {
    return http({
      url: `/product/order/prePurchase`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 自营提交订单
  productOrderInfo(data) {
    return http({
      url: `/product/order/submitOrderInfo`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 三方提交订单
  submitOrderInfo(data) {
    return http({
      url: `/order/cake/submitOrderInfo`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 查询出票详情
  queryMovieTicketDetail(orderId, qmmOrderId) {
    return http({
      url: `/movieTicketDetail/queryMovieTicketDetail?orderId=${orderId}&qmmOrderId=${qmmOrderId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 购物车数据
  getCard(data) {
    return http({
      url: `/shoppingCart/queryShoppingCart`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 删除购物车数据
  deleteShop(data) {
    return http({
      url: `/shoppingCart/deleteShoppingCart`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  // 修改购物车单个商品数量
  updateNum(data) {
    return http({
      url: `/shoppingCart/updateNum`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  // 扫一扫获取微信相关参数
  wxUrlSign(str) {
    return http({
      url: `/wechat/wxUrlSign?url=${str}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  // 上传扫一扫结果
  boundBenefitCardByQR(str, ticketType) {
    return http({
      url: `/benefitCardInfo/boundBenefitCardByQR?qrCodeContent=${str}&ticketType=${ticketType}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 获取卡号信息
  checkBenefitCard(data) {
    return http({
      url: `/cardPwd/checkBenefitCard`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  // 绑卡
  bindCard(data) {
    return http({
      url: `/cardPwd/bindBenefitCard`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 福利卡列表
  cardList(data) {
    return http({
      url: `/cardPwd/queryBindBenefitCard`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 判断是否有首页标识
  isHomeShow(data) {
    return http({
      url: `/product/category/ifShowHomePage?bathId=${data.bathId}&upgradeFlag=${data.upgradeFlag}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 福利卡个数
  cardAvaAndUnAvaNum(data) {
    return http({
      url: `/cardPwd/cardAvaAndUnAvaNum`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //福利卡升级
  cardUpgrade(cardId) {
    return http({
      url: `/cardPwd/cardUpgrade?cardId=${cardId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 蛋糕品牌列表
  getBrandInfo(cityCode, cardId, productType) {
    return http({
      url: `/cakeMall/getBrandInfo?cityCode=${cityCode}&cardId=${cardId}&productType=${productType}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  // 蛋糕品牌列表
  queryBannerAndProductInfoH5(data) {
    return http({
      url: `/hostShow/queryBannerAndProductInfoH5`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 蛋糕品牌列表
  queryBannerAndProductInfoH5New(data) {
    return http({
      url: `/hostShow/queryBannerAndProductInfoH5New`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 蛋糕品牌列表
  getProductHotList(data) {
    return http({
      url: `/cakeMall/getProductHotList`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  // 蛋糕详情
  getProductDetail(data) {
    return http({
      url: `/cakeMall/getProductDetail`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //p配送规则
  queryDistributionRules(data) {
    return http({
      url: `/cakeMall/queryDistributionRules`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 商家地址列表
  getShopList(addressId, brandId) {
    return http({
      url: `/order/cake/getShopList?addressId=${addressId}&brandId=${brandId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  //给蛋糕店家地址
  addCakeAddress(data) {
    return http({
      url: `/cakeMall/addCakeAddress`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 自营蛋糕列表
  queryAutarkyCakeInfo(data) {
    return http({
      url: `cakeMall/queryAutarkyCakeInfo`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  /**
   * 电商获取当前商品配送规则及是否合规
   * @param {*} data {
   *    cityCode: "", 城市code
   *    specId: "", 规格标识
   *    addrId: "", 地址标识
   *    quantitys: "" 购买数量
   *    distributionRuleId: "" 配送规则id
   * }
   * @returns
   */
  queryDistributionRules(data) {
    return http({
      url: `/cakeMall/queryAllDistributionRules`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  /**
   * 电商获取详情请求接口
   * @param {*} data {
   *    cardId: "",
   *    cityCode: "",
   *    brandId: "",
   *    productId: ""}
   * @returns
   */
  queryAllGoodsDetail(data) {
    return http({
      url: `/cakeMall/queryAllProductDetail`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 规格信息列表
  queryAutarkyCakeInfoDetail(productId) {
    return http({
      url: `/cakeMall/queryAutarkyCakeInfoDetail?productId=${productId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //查看首页商品信息
  queryProductInfoHome(data) {
    return http({
      url: `/product/info/queryProductInfoHome`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //查看首页商品信息
  queryProductInfoHomeNew(data) {
    return http({
      url: `/product/info/queryProductInfoHomeNew`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //品牌的商品列表
  queryProductInfoBrand(data) {
    return http({
      url: `/product/info/queryProductInfoBrand`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 校验地址是否支持配送 购物车点击提交按钮时进行调用
  checkIsDelivery(data) {
    return http({
      url: `/product/order/checkIsDelivery`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  checkSelfSupIsDelivery(data) {
    return http({
      url: `/product/order/checkSelfSupIsDelivery`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 查询购物车地址参数
  queryCheckIsDelivery(key) {
    return http({
      url: `/product/order/queryCheckIsDelivery`,
      method: 'get',
      params: { key },
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  // 确认订单切换地址
  reCountExpressFee(key, addressId) {
    return http({
      url: `/product/order/reCountExpressFee`,
      method: 'get',
      params: { key, addressId },
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //自营订单详情
  queryOrderInfoDetail(orderNo) {
    return http({
      url: `/product/order/queryOrderInfoDetail?orderNo=${orderNo}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //订单详情
  queryCakeOrderDetail(orderNo) {
    return http({
      url: `/order/cake/queryCakeOrderDetail?orderNo=${orderNo}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //查看物流
  queryEbusiness(data) {
    return http({
      url: `/OrderLogistics/queryLogisticsInfo`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  //确认收货
  cakeConfirmReceipt(orderNo, sortId) {
    return http({
      url: `/cakeMall/cakeConfirmReceipt?orderNo=${orderNo}&sortId=${sortId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //自营确认收货
  selfConfirmReceipt(orderNo, sortId) {
    return http({
      url: `/product/order/selfConfirmReceipt?orderNo=${orderNo}&sortId=${sortId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  /**
   * 全品 首页 二级分类菜单查询
   * @author maomao
   * @param {*} param0
   * @returns
   */
  queryCardTwoCategory({ cardId }) {
    return http({
      url: `/product/category/queryCardTwoCategory?cardId=${cardId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  queryCardOneCategoryNew(id, bathId, upgradeFlag, cityCode) {
    return http({
      url: `/product/category/queryCardOneCategoryNew?pid=${id}&bathId=${bathId}&upgradeFlag=${upgradeFlag}&cityCode=${cityCode}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  queryCardOneCategoryTop(bathId, upgradeFlag, cityCode) {
    return http({
      url: `/product/category/queryCardOneCategoryTop?bathId=${bathId}&upgradeFlag=${upgradeFlag}&cityCode=${cityCode}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  queryCardTwoCategoryNew(id, bathId, upgradeFlag) {
    return http({
      url: `/product/category/queryCardTwoCategoryNew?pid=${id}&bathId=${bathId}&upgradeFlag=${upgradeFlag}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  /**
   * 全品 首页 二级分类菜单查询
   * @author maomao
   * @param {*} param0
   * @returns
   */
  queryCardOneCategory({ cardId }) {
    return http({
      url: `/product/category/queryCardOneCategory?cardId=${cardId}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  //添加支付密码
  pwdAdd(data) {
    return http({
      url: `/tPayPwd/add`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //更新支付密码
  pwdUpdate(data) {
    return http({
      url: `/tPayPwd/update`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //是否设置过支付密码
  pwdCheck() {
    return http({
      url: `tPayPwd/check`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //密码校验
  pwdContrast(data) {
    return http({
      url: `/tPayPwd/contrast`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  //密码校验
  queryThirdAddress(cityCode) {
    return http({
      url: `/movieMall/queryThirdAddress?cityCode=${cityCode}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  //卡消费记录
  queryCardExpendByCardId(data) {
    return http({
      url: `/cardExpend/queryCardExpendByCardId`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  //企业采购
  addCompanyPurchase(data) {
    return http({
      url: `/companyPurchase/addCompanyPurchase`,
      method: 'post',
      data: data,
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },

  //通知
  queryNotice() {
    return http({
      url: `/platform/notice/queryNotice`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
  //获取地址列表
  queryCityList(cityName) {
    return http({
      url: `/city/queryCityTripartite?type=1&provinceName=${cityName}`,
      method: 'get',
      headers: { Authorization: 'Bearer ' + this.getToken() },
    });
  },
    
 //获取跳转三方链接
 getEntrance(type) {
  return http({
    url: `/tripartiteProduct/entrance?cardId=${localStorage.getItem("cardId")}&type=${type}`,
    method: 'get',
    headers: { Authorization: 'Bearer ' + this.getToken() },
  });
},

//根据三方订单号查询订单
queryPayOrderInfo(orderNo) {
  return http({
    url: `/product/order/queryPayOrderInfo?orderNo=${orderNo}`,
    method: 'get',
    headers: { Authorization: 'Bearer ' + this.getToken() },
  });
},
//点餐二级分类查询
selectPlatformTwoCategory(bathId, upgradeFlag, cityCode) {
  return http({
    url: `/product/category/selectPlatformTwoCategory?bathId=${bathId}&upgradeFlag=${upgradeFlag}&cityCode=${cityCode}`,
    method: 'get',
    headers: { Authorization: 'Bearer ' + this.getToken() },
  });
},

//获取卡余额接口
jumpHomePage(cardId) {
  return http({
    url: `/cardPwd/jumpHomePage?cardId=${cardId}`,
    method: 'get',
    headers: { Authorization: 'Bearer ' + this.getToken() },
  });
},
  /**
   * 封装get方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  gets(url, params = {}) {
    return new Promise((resolve, reject) => {
      http.get(url, {
        params: params,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //获取
  getBDAddress(searchstr, region) {
    return BDRequest({
      method: 'GET',
      url:
        '/place/v2/suggestion?query=' +
        searchstr +
        '&region=' +
        region +
        '&ak=2f0a4baa6518a3d23a18177d72c41df5&city_limit=true',
    });
  },


  getToken() {
    return localStorage.getItem('access_token');
  },
};
