<template>
  <div class="flex box jcsb pr-5"
    :style="{ backgroundColor: changeColor ? 'rgb(255,150,105,' + transparent + ')' : 'rgb(107,200,234,' + transparent + ')' }">
    <!-- 插槽 -->
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="middle">
      <slot name="middle"></slot>
    </div>

    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadChild",
  props: {
    changeColor: null,
    change: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      transparent: 0,
    };
  },
  components: {},
  methods: {
    onScroll() {
      // 获取当前文档流的 scrollTop
      if (this.change) {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        this.transparent = scrollTop * 0.01
      }

    },

  },
  mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll, false)
  },
  computed: {},
  watch: {},
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  },
};
</script>

<style lang='scss' scoped>
.box {
  background-color: rgb(255, 255, 255, 0);
  // background-image: linear-gradient(top, #0909094e, #09090907);
  // background-size: 100%;
  .left {
    width: 18%;
    text-align: center;
  }

  .middle {
    width: 70%;

    .van-search__content {
      padding-left: 0;
      text-indent: 5px;
    }

    .van-search {
      padding: 0;
      padding-right: 5px;
      border-radius: 1rem;
      width: 100%;

      .van-cell {
        padding-left: 0 !important;

        width: 100%;
      }
    }
  }

  .right {
    width: 12%;
    text-align: center;
  }
}
</style>