<template>
  <div class="tow-level-product-category-wrapper">
    <template v-if="isSticky">
      <van-sticky :offset-top="offsetTop">
        <!-- <van-tabs ref="towLevelVanTabsRef" color="#ff976a" @change="handleTabsClick" v-model="navIndex"
          :ellipsis="false" :line-width="80">
          <van-tab v-for="(item, index) in dataList" :title="item.name" :key="index" />
        </van-tabs> -->
        <CakaTab :title="dataList" @scrollToAll="handleTabsClick"></CakaTab>
      </van-sticky>
    </template>
    <template v-else>
      <div>
        <!-- <van-tabs ref="towLevelVanTabsRef" color="#ff976a" @change="handleTabsClick" v-model="navIndex"
          :ellipsis="false" :line-width="80">
          <van-tab title-class="tab-title-text" v-for="(item, index) in dataList" :title="item.name" :key="index" />
        </van-tabs> -->
        <CakaTab :title="dataList" @scrollToAll="handleTabsClick"></CakaTab>
      </div>
    </template>
  </div>
</template>

<script>
import CakaTab from "../../components/cake/CakaTab.vue";
export default {
  props: {
    /**
     * 二级菜单是否吸顶
     */
    isSticky: {
      type: Boolean,
      default: true
    },
    /**
     * 吸顶时与顶部的距离，支持 px vw vh rem 单位，默认 px
     */
    offsetTop: {
      type: Number | String,
      default: 50
    },
    /**
     * 展示标签的list
     * { name: String, ...rest }
     */
    list: {
      type: Array,
      default: () => []
    },
    /**
     * 当不传入list时可以传入条件进行接口查询
     * { params: Object, handleQuery: Function }
     */
    queryOptions: {
      type: Object,
      default: () => {
        return {
          upgradeFlag: '',
          bathId: '',

        };
      }
    }
  },
  data() {
    return {
      navIndex: 0, // 当前选着的index
      dataList: [], // 数据列表
      dquData: [],
      cityCode: '',
    };
  },
  components: {
    CakaTab
  },
  created() {
    this.upgradeFlag = localStorage.getItem("upgradeFlag")
    this.bathId = localStorage.getItem("bathId")
    this.cityCode = localStorage.getItem("cityCode")
    // this.init();
  },
  methods: {
    next() {
      if (this.navIndex !== this.dataList.length - 1) {
        const idx = ++this.navIndex;
        this.$refs.towLevelVanTabsRef.scrollTo(idx);
        this.handleTabsClick(idx);
      }
    },
    /**
     * 初始化二级标签列表
     */
    async init() {
      // if (!(Array.isArray(this.list) && this.list.length > 0)) {
      //   const { params, handleQuery } = this.queryOptions;
      //   const queryParams = params || { cardId: localStorage.getItem("cardId") };
      //   let res = null;
      //   if (typeof handleQuery === "function") {
      //     res = await handleQuery(queryParams);
      //     this.dataList = res.data;
      //   } else {
      //     res = await this.$api.queryCardOneCategoryNew('', this.bathId, this.upgradeFlag, this.cityCode);
      //     this.dataList = res.data;
      //   }
      // } else {
      //   this.dataList = [...this.list];
      // }
      this.dataList.length = 0
      this.navIndex = 0
      this.getQueryRotation()
    },
    getQueryRotation() {
      let form = {
        cardId: localStorage.getItem("cardId"),
        cityCode: this.cityCode,
        upgradeFlag: this.upgradeFlag,
        bathId: this.bathId,
        hotType: "D",
        showPositionCode: "0"
      }
      this.$api
        .getQueryRotation(form)
        .then((res) => {
          this.dquData = []
          if (res.data) {
            res.data.forEach(element => {
              if (element.region == 'D') {
                let cakaInfo = {
                  description: element.description,
                  name: element.name,
                  id: element.showImgId.id,
                  isShowD: true,
                }
                this.dquData.push(cakaInfo)
              }
            });
            if (this.dquData.length > 0) {
              this.dataList.unshift(...this.dquData)
            }
          }
          this.handleLoadedAfter();
        })
        .catch((err) => {
          this.handleLoadedAfter();
        });
    },
    handleLoadedAfter() {
      this.$emit("loadedAfter", { data: this.dataList[0], index: 0 });
    },
    /**
     * 标签列表点击执行函数
     */
    handleTabsClick(index) {
      console.log(index);
      this.$emit("change", { data: this.dataList[index], index });
    }
  }
};
</script>

<style lang="scss" scoped>
.tow-level-product-category-wrapper {
  margin-bottom: 0.5rem;
}
</style>
