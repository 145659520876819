<template>
  <div class="foot">
    <van-tabbar v-model="active" safe-area-inset-bottom route active-color="#ee0a24" inactive-color="#000">
      <van-tabbar-item replace to="/HomeNew">
        <div class="t-a-c">
          <van-icon name="home-o" size="1.5rem" />
        </div>
        <div class="t-a-c mt">
          首页
        </div>
      </van-tabbar-item>
      <van-tabbar-item replace to="/Classification">
        <div class="t-a-c">
          <van-icon name="apps-o" size="1.5rem" />
        </div>
        <div class="t-a-c mt">
          分类
        </div>
      </van-tabbar-item>
      <van-tabbar-item replace to="/AllCardViews">
        <div class="t-a-c">
          <van-icon name="point-gift-o" size="1.5rem" />
        </div>
        <div class="t-a-c mt">
          福利卡
        </div>
      </van-tabbar-item>
      <van-tabbar-item replace to="/myCart">
        <van-badge max="20">
          <div class="t-a-c">
            <van-icon name="shopping-cart-o" size="1.5rem" />
          </div>
          <div class="t-a-c mt">
            购物车
          </div>
        </van-badge>
      </van-tabbar-item>
      <van-tabbar-item replace to="/AllMy">
        <div class="t-a-c">
          <van-icon name="contact" size="1.5rem" />
        </div>
        <div class="t-a-c mt">
          我的
        </div>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      active: 0,
      badge: 3,
    };
  },
  components: {},
  methods: {},
  mounted() { },
  computed: {
  },
  watch: {},
};
</script>

<style lang='scss' scoped>
.foot {
  .iconfont {
    font-size: 1.3rem;
  }
}

.mt {
  margin-top: 0.2rem;
  font-size: 0.8rem;
}

.van-tabbar {
  height: 4rem;
}
</style>