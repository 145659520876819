<template>
  <div class="contaier2">
    <div class="top">
      <span> 好物推荐 </span> <span style="color: white;font-size:0.75rem;margin-top:0.375rem;" @click="showToast"> 更多</span>
    </div>
    <div style="display: flex;flex-direction: row; margin-left: 1rem;margin-top: -2rem;margin-right: 0.9375rem;">
      <div v-for="(item, index) in floor" :key="index" @click="goDetail(item.id)" style="display: flex;flex-direction: column;width: 30%; margin-right: 0.75rem;justify-content: center;align-items: center">
        <!-- 商品 -->
        <van-image :src="item.coverImage" fit="cover" style=" height: 5.8rem;width: 5.8rem;" radius="10px"></van-image>
        <div class="texttitle">{{ item.name }}{{ item.name }}{{ item.name }}{{ item.name }}</div>
        <div style="font-size: 0.875rem;color: #D23B2E;width:5.8rem">{{ item.showPrice }} 点</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "",
  props: {
    floor: {
      type: Array,
    },
  },
  data () {
    return {
      curStartTime: '2023-10-31 08:00:00',
      day: '0',
      hour: '00',
      min: '00',
      second: '00',
      time: 0,
      timeData: null,
    };
  },
  components: {},
  methods: {
    goDetail(val) {
      // //  传递参数
      // if (val.flag == '1') {
      //   this.$router.push({
      //     path: "/Details",
      //     query: {
      //       id: val.id,
      //       brandId: val.category2Id
      //     },
      //   });
      // }else{
      //    this.goZYDetail(val.id)
      // }
    },
    goZYDetail(val) {
      this.$router.push({
        path: "/Details",
        query: {
          id: val,
          specId: val.specId,
        },
      });
    },
    showToast () {
      this.$Toast("敬请期待！");
    },
    goClass (val) {
      if (val == 4) {
        this.$router.push({
          path: "/Movies",
          query: {
            index: val,
          },
        });
      } else if (val == 0) {

        this.$router.push({
          path: "/CakeList",
          query: {
            index: val,
          },
        });
      }

    },
    // 倒计时
    countTime () {
      // 获取当前时间
      // let date = new Date()
      // let now = date.getTime()
      // // 设置截止时间
      // let endDate = new Date(this.curStartTime) // this.curStartTime需要倒计时的日期
      // // console.log("timeDate--->"+this.timeData.hours+" timeData.minutes  "+this.timeData.minutes);
      // let end = endDate.getTime()
      // // 时间差
      // this.time = end - now

      // // 定义变量 d,h,m,s保存倒计时的时间
      // if (leftTime >= 0) {
      //   // 天
      //   this.day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
      //   // 时
      //   let h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
      //   this.hour = h < 10 ? '0' + h : h
      //   // 分
      //   let m = Math.floor(leftTime / 1000 / 60 % 60)
      //   this.min = m < 10 ? '0' + m : m
      //   // 秒
      //   let s = Math.floor(leftTime / 1000 % 60)
      //   this.second = s < 10 ? '0' + s : s
      // } else {
      //   this.day = 0
      //   this.hour = '00'
      //   this.min = '00'
      //   this.second = '00'
      // }

      // // 等于0的时候不调用
      // if (Number(this.hour) === 0 && Number(this.day) === 0 && Number(this.min) === 0 && Number(this.second) === 0) {
      //   return
      // } else {
      //   // 递归每秒调用countTime方法，显示动态时间效果,
      //   setTimeout(this.countTime, 1000)
      // }
    },


  },
  mounted () {
    // this.countTime();
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.contaier2 {
  overflow: hidden;
  background-color: white;
  border-radius: 0.5rem;
  margin: 0.5rem;
  padding-bottom: 1rem;
  font-size: 1.125rem;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
  .box {
    height: 6.4rem;

    .flex-1 {
      img {
        width: 100%;
      }
    }
  }

  .floor {
    background-color: rgb(226, 79, 21);
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border-radius: 100%;
  }
}
.texttitle {
  font-size: 0.75rem;
  color: #222222;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.125rem;
  width: 5.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.image1 {
  height: 6.4rem;
  width: 100%;
  border-radius: 0.3rem;
}

.top {
  background-image: url("../../assets/images/recommend-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 3.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.colon {
  display: inline-block;
  margin: 0 0.1rem;
  color: #ee0a24;
}

.block {
  display: inline-block;
  width: 1.2rem;
  padding: 0rem;
  color: #fff;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 0.2rem;
  background-color: #ee0a24;
  height: 1.2rem;
}

.image2 {
  height: 3.2rem;
  width: 100%;
  border-radius: 0.3rem;
}
</style>