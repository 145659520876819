<template>
  <!--轮播图 -->
  <div>
    <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white" style="height: 100%; border-radius: 0.5rem;"
      :show-indicators="showIndicators">
      <van-swipe-item v-for="(item, index) in headData" :key="index">
        <van-image lazy-load :src="item.showImgId.showImg" fit="cover" alt="" class="img"
           @click="queryTopicBoolean(item)" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: "indexHead",
  props: {
    headData: {
      type: Array,
    },
    showIndicators: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: null,
    };
  },
  components: {},
  methods: {
    queryTopicBoolean(item) {
      let form = {
        cardId: localStorage.getItem("cardId"),
        cityCode: localStorage.getItem("cityCode"),
        upgradeFlag: localStorage.getItem("upgradeFlag"),
        bathId: localStorage.getItem("bathId"),
        size: 10,
        current: 1,
        hotType: "A",
        id: item.showImgId.id
      }
      this.$api
        .queryTopicBoolean(form)
        .then((res) => {
          if (res.data) {
            if (res.data.flag) {
              item.flag = true
              item.showType = res.data.showType
              item.productId = res.data.productId
              item.brandId = res.data.brandId
              this.LabelClick(item)
            } else {
              this.$Toast('敬请期待')
            }
          } else {
            Toast(res.msg)
          }
        })
        .catch((err) => {
        });
    },

    LabelClick(val) {
      if (val.showType == '1') {
        this.$router.push({
          path: "/LableList",
          query: { activityId: val.showImgId.id, type: 'A' }
        });
      } else if (val.showType == '2') {
        this.$router.push({
          path: "/LableClassList",
          query: { activityId: val.showImgId.id, type: 'A' }
        });
      } if (val.showType == '3') {
        if (val.productId) {
          //  传递参数
          this.$router.push({
            path: "/Details",
            query: {
              id: val.productId,
              brandId: val.brandId,
            },
          });
        } else {
          this.$Toast('敬请期待')
        }
      }
    },
  },
  mounted() {
    // console.log(this.headData);
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.box {
  padding: 10px 5px;
  background-color: #f5f5f5;

  .address {
    width: 15%;
    text-align: center;
  }

  .van-search__content {
    padding-left: 0;
    text-indent: 5px;
  }

  .van-search {
    background-color: #fff;
    padding: 0;
    padding-right: 5px;

    width: 72%;

    .van-cell {
      padding-left: 0 !important;
      background-color: #fff;
      width: 100%;
    }
  }
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: black;
}
</style>