<template>
  <div style="display: flex;flex-direction: column;">
    <div style="display: flex;flex-direction: row;background-color: white;align-items: center;">
      <div class="topNav" id="topNav">
        <ul id="topNavUl">
          <li v-for="(i, index) in title" :key="index" :ref="('card-' + index)" @click="goCenter(i.id, index, $event)">
            <div style="display: flex;flex-direction: column;align-items: center;">
              <span :class="selectindex === index ? 'title-sty' : 'untitle-sty'">{{ i.name }}</span>
              <span v-if="i.description" :class="selectindex === index ? 'context-sty' : 'uncontext-sty'">{{ i.description }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    title: {
      type: Array
    },
    index: {
      type: Number,
      default: 0
    },
    type: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      selectindex: this.index,
      currentTopNav: '',
      show: false,
      getDate: true
    }
  },
  created() { },
  components: {},
  methods: {
    clossPop() {
      this.show = false
    },
    goCenter(id, index, e) {
      if (this.show) {
        this.show = false
      }
      this.selectindex = index
      let destination = index - 2;
      destination = destination < 0 ? 1 : destination;
      this.currentTopNav = id;
      if (this.navScroll) {
        this.navScroll.scrollToElement(
          document.querySelector('#topNav li:nth-child(' + destination + ')')
        );
      }
      this.getdata(index);
      // 横向滑动居中
      let ul = document.querySelector('#topNavUl');
      let nav = document.getElementById("topNav");
      let window_offsetWidth = window.innerWidth; //屏幕宽度;
      let dom = e.target;
      if (dom && ul) {
        let domoffsetWidth = dom.offsetLeft,
          //中间值 =( 屏幕宽度 - li宽度 ) / 2;
          diffWidth = (window_offsetWidth - dom.offsetWidth) / 2,
          //目标值 = offset - 中间值
          targetOffset = -(domoffsetWidth - diffWidth);
        let ul_width = ul.getBoundingClientRect().width;//开始

        // 未超出中间值
        if (-targetOffset < 0) {
          nav.scrollLeft = 0;
          return;
        }
        //末尾
        if (targetOffset < window_offsetWidth - ul_width) {
          nav.scrollLeft = -(window_offsetWidth - ul_width);
          return;
        }
        //正常
        nav.scrollLeft = -targetOffset
      }
    },
    getdata(index) {
      if (this.getDate) {
        this.$emit('scrollToAll', index)
      } else
        this.getDate = true
    }

  },
  mounted() {
  },
}

</script>

<style lang="scss" scoped>
.topNav {

 
  display: flex;
  flex: 1;
  overflow: scroll;
  white-space: nowrap;

  ul {
    display: inline-block;
    white-space: nowrap;

    li {
      display: inline-block;
      font-size: 1rem;
      padding: 0 0.1rem;

      &:first-child {}

      &:last-child {}
    }
  }
}





.title-sty {
  // border-radius: 1rem;
  // background-color: #D59A54;
  color: #6CC9EA;
  font-size: 0.85rem;
  margin: 0 0.5rem;
  margin-top: 0.5rem;
  padding: 0.04rem 0rem 0 0rem;
  // border-bottom: 1px solid #d59a54;
}

.untitle-sty {

  border-radius: 1rem;
  background-color: #fff;
  border-bottom: 1px solid #ffffff;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  padding: 0.04rem 0.5rem 0 0.5rem;
}
.context-sty {
  // border-radius: 1rem;
  // background-color: #D59A54;
  color: white;
  font-size: 0.7rem;
  margin: 0 0.5rem 0.5rem 0.5rem;
  padding: 0.04rem 0.5rem 0.05rem 0.5rem;
  background-color: #6CC9EA;
  border-radius: 1rem;
}

.uncontext-sty {
  font-size: 0.7rem;
  margin: 0 0.5rem 0.5rem 0.5rem;
  color: #666;
  padding: 0.04rem 0.5rem 0.05rem 0.5rem;
}


.pop {
  width: 100%;
  position: absolute;
  top: 9rem;
  overflow-y: scroll;
  z-index: 9999;
  background-color: #F8F8F8;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  padding-bottom: 1rem;
}
</style>