<template>
  <div id="app " class="app">
    <keep-alive v-if="isRouterAlive">
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      _beforeUnload_time: '',
      _gap_time: '',
      isRouterAlive: true
    };
  },
  provide() { return { reload: this.reload }; },
  created() {

  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => (this.isRouterAlive = true));
    }
  },
  mounted() {
    if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
      window.entryUrl = location.href.split('#')[0]
    }
  },
  computed: {},
  watch: {},
  destroyed() {
    // window.removeEventListener('beforeunload')
    // window.removeEventListener('unload')
  }
};
</script>

<style lang='scss'>
@import "./styles/ui";
@import "./styles/common";

// 高度
html,
body,
.app {
  height: 100%;
  background-color: #F8F8F8;
  // font-family: PingFangSC-Medium;
  // font-family: 'Regular';
  // letter-spacing: 1px;
  font-family: Helvetica Neue, Helvetica, sans-serif;
}

#app {
  padding-bottom: 20px;
}

* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: none;
}
</style>