<template>
  <!-- 推荐 -->
  <div class="container1">
    <div ref="wrapper" class="wrapper">
      <div class="flex" style="flex: 1;margin: 0 1rem;">
        <div v-for="(item, index) in dataList" :key="index" class="recomen" @click="queryTopicBoolean(item)">
          <img alt="" class="img" v-lazy="item.showImgId.showImg" @load="loadfinish" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BScroll from "better-scroll";
import { Toast } from "vant";
export default {
  name: "",
  props: {
    dataList: {
      type: Array,
    },
  },
  data() {
    return {
      username: "",
    };
  },
  components: {},
  methods: {
    queryTopicBoolean(item) {
      let form = {
        cardId: localStorage.getItem("cardId"),
        cityCode: localStorage.getItem("cityCode"),
        upgradeFlag: localStorage.getItem("upgradeFlag"),
        bathId: localStorage.getItem("bathId"),
        size: 10,
        current: 1,
        hotType: "B",
        id: item.showImgId.id
      }
      this.$api
        .queryTopicBoolean(form)
        .then((res) => {
          if (res.data) {
            if (res.data.flag) {
              item.flag = true
              item.showType = res.data.showType
              item.productId = res.data.productId
              item.brandId = res.data.brandId
              this.goCakaList(item)
            } else {
              Toast('敬请期待')
            }
          } else {
            Toast(res.msg)
          }
        })
        .catch((err) => {
        });
    },
    goCakaList(val) {
      if (val.showType == '1') {
        this.$router.push({
          path: "/LableList",
          query: { activityId: val.showImgId.id, type: 'B' }
        });
      } else if (val.showType == '2') {
        this.$router.push({
          path: "/LableClassList",
          query: { activityId: val.showImgId.id, type: 'B' }
        });
      } if (val.showType == '3') {
        if (val.productId) {
          //  传递参数
          this.$router.push({
            path: "/Details",
            query: {
              id: val.productId,
              brandId: val.brandId,
            },
          });
        } else {
          this.$Toast('敬请期待')
        }
      }
    },
    // 横向滚动
    scroll() {
      this.$nextTick(() => {
        this.scroll = new BScroll(this.$refs.wrapper, {
          startX: 0, // 配置的详细信息请参考better-scroll的官方文档，这里不再赘述
          click: false,
          scrollX: true,
          scrollY: false,
          mouseWheel: true,//开启鼠标滚轮
          disableMouse: false,//启用鼠标拖动
          disableTouch: false,//启用手指触摸
          // 在手机端 让手指放在滚动区域也可以竖向滚动
          eventPassthrough: "vertical",
        });
      });
    },
    loadfinish() {
      this.scroll && this.scroll.refresh()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.scroll();
    });
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.container1 {
  margin-top: 1rem;
  border-radius: 0.5rem;

  .wrapper {
    display: flex;
    overflow: hidden;
  }

  .recomen {
    width: 9rem;
    height: 6rem;
    margin-right: 1rem;
    background-color: white;
    border-radius: 0.3rem;
    margin-bottom: 1rem;

    .img {
      height: 100%;
      width: 100%;
      border-radius: 0.3rem;
      vertical-align: middle;
    }


  }

}
</style>