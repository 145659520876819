<template>
  <!-- 搜索时的页面 -->
  <div :class="type == 1 ? 'mtType1' : 'mtType2'">
    <!-- 搜索记录 -->
    <div v-if="history">
      <!-- 记录为空 -->
      <div v-if="!historydata">
        <van-empty description="暂无搜索记录" class="empty" />
      </div>
      <!-- 不为空 -->
      <div v-else class="p-5">
        <div class="bgc-white m-tb-10 p-10 flex jcsb">
          <div>历史记录</div>
          <div @click="localDelete">清空历史记录</div>
        </div>
        <div class="flex flex-wrap">
          <div v-for="item in historydata" :key="item" class="bgc-white b-radius-10 his" style="padding: 0.2rem 0.8rem"
            @click="reSeach(item)">
            {{ item }}
          </div>
        </div>
      </div>
    </div>

    <!-- 搜索结果 -->
    <div v-else class="height-100 ">
      <div>
        <div v-if="zyData.length === 0" class="bgc-low-gray">
          <van-empty class="custom-image empty" description="暂无该商品信息" />
        </div>
        <van-list v-else v-model="loading2" :finished="finished" finished-text="没有更多了" @load="nextPage">
          <goods-list-com :DataList="zyData"> </goods-list-com>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import GoodsListCom from "../../components/GoodsListCom.vue";
export default {
  name: "",
  props: {
    value: {
      type: String,
    },
    type: {
      type: Number
    },
    id: {
      type: Number
    },
    hotType: {
      type: String
    }
  },
  data() {
    return {
      loading2: false, // 商品列表是否处于加载状态
      finished: false, // 是否加载完了
      // 搜索历史
      history: true,
      // 数据
      historydata: null,
      zyData: [],
      zyform: {
        cardId: '',
        current: 1, // 当前页码
        size: 10, // 每页个数
        productName: '',
        cityCode: '',
        upgradeFlag: localStorage.getItem("upgradeFlag"),
        bathId: localStorage.getItem("bathId"),
        id: undefined,
        hotType: undefined,
        priceType: null,
      },
      //
      username: null,
    };
  },
  components: { GoodsListCom },
  methods: {
    goZYDetail(val) {
      this.$router.push({
        path: "/Details",
        query: {
          id: val.id,
          brandId: val.brandId ? val.brandId : val.category2Id,
          specId: val.specId,
        },
      });
    },
    goDetail(val) {
      this.$router.push({
        path: "/Details",
        query: {
          id: val.id,
          brandId: val.category2Id ? val.category2Id : val.brand_id,
          category1Id: val.category1Id,
          category2Id: val.category2Id
        },
      });
    },
    getTabIndex(index) {
      this.tabindexL = index
    },
    // 获取搜索记录
    getHistroy() {
      // 登陆获取用户的搜索记录
      if (this.username) {
        this.historydata = JSON.parse(
          localStorage.getItem(`${this.username}seachHistory`)
        );
      } else {
        // 没有登陆获取公共的记录
        // 获取本地存储记录
        this.historydata = JSON.parse(localStorage.getItem("seachHistory"));
      }
    },

    // 清除本地数据
    localDelete() {
      let key;
      if (this.username) {
        key = `${this.username}seachHistory`;
      } else {
        key = "seachHistory";
      }
      if (localStorage.getItem(`${key}`) === null) {
        return;
      } else {
        localStorage.removeItem(`${key}`);
      }
      this.historydata = null;
    },
    // 点击关键字搜索
    seach(val) {
      if (!Boolean(val)) {
        Toast.fail("未输入关键字");
        this.history = true;
      } else {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
          loadingType: "spinner",
          duration: "1000",
        });
        this.zyform.productName = val
        if (this.id) {
          this.zyform.id = this.id
        }
        if (this.hotType) {
          this.zyform.hotType = this.hotType
        }
        //自营商品
        this.getData()
        if (this.username) {
          this.$utils.saveHistory({
            key: `${this.username}seach`,
            data: val,
          });
        } else {
          // 添加本地储存记录
          this.$utils.saveHistory({ key: "seach", data: val });
        }
      }
    },
    setPriceType(val) {
      this.zyform.priceType = val
      this.zyform.current = 1
      this.getData()
    },
    getData() {
      this.$api
        .queryProductInfoHomeNew(this.zyform)
        .then((res) => {
          if (res.code == 1) {
            Toast(res.msg);
            return;
          }
          this.history = false;
          if (this.zyform.current == 1) {
            this.zyData = res.data.records;
          } else {
            this.zyData.push(...res.data.records);
          }
          if (this.zyData.length > 0) {
            this.showTop()
          }
          this.loading2 = false;
          if (this.zyform.current >= res.data.pages) {
            this.finished = true;
          }
          // 登陆存储该用户的搜索关键字  未登录粗存游客搜索的记录
        })
        .catch((err) => {
          console.log(err);
        });
    },

    nextPage() {
      // 加载下一页
      if (this.zyData.length >= 10) {
        this.loading = false;
        this.zyform.current += 1;
        this.getData();
      }
    },
    showTop() {
      this.$emit("showtop");
    },
    // 点击历史再次搜索
    reSeach(val) {
      this.seach(val);
      this.$emit("value", val);
    },
    reset() {
      this.loading2 = false
      this.finished = false
      this.history = true
      this.historydata = null
      this.zyData = []
      this.zyform = {
        cardId: localStorage.getItem('cardId'),
        current: 1, // 当前页码
        size: 10, // 每页个数
        productName: '',
        cityCode: localStorage.getItem('cityCode'),
        upgradeFlag: localStorage.getItem("upgradeFlag"),
        bathId: localStorage.getItem("bathId"),
        id: undefined,
        priceType: null,
        hotType: undefined
      },
        //
        localStorage.getItem("userInfo")
          ? (this.username = JSON.parse(localStorage.getItem("userInfo")).username)
          : "";
      this.getHistroy();
    }
  },

  mounted() {
    localStorage.getItem("userInfo")
      ? (this.username = JSON.parse(localStorage.getItem("userInfo")).username)
      : "";
    this.zyform.cardId = localStorage.getItem('cardId')
    this.zyform.cityCode = localStorage.getItem('cityCode')
    this.getHistroy();
  },
  computed: {},
  watch: {
    // 输入框的值为空时
    value() {
      if (!this.value) {
        this.history = true;
        this.getHistroy();
      }
    },

    '$route': function () {
      this.$waterfall.forceUpdate()

    },
  },
};
</script>

<style lang='scss' scoped>
.container-water-fall {

  /* // padding: 0 28px; */
  padding: 0 3%;
  width: 100vw;
  box-sizing: border-box;
  /* background: #fafafa !important; */
}

.cell-item {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 5% 0;
}

.cell-item img {
  width: 100%;
  height: auto;
  display: block;
}

.his {
  height: 20px;
  line-height: 20px;
  margin: 5px 10px;
  background-color: rgba(224, 221, 221, 0.5);
}

.mtType1 {
  padding-top: 0.3rem;
}

.mtType2 {
  padding-top: 0.3rem;
  padding-bottom: 4rem;
}

.price {
  color: #f00;
  margin-left: 0.5rem;
  font-size: 1rem;
  padding-bottom: 0.2rem;
}

.seach-img {
  width: 20%;
}
</style>